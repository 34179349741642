@media (min-width: 1280px){
  .container {
      max-width: 90%;
  }
}

pre.CodeMirror-line {
  text-shadow: none;
}

.header-toggler {  
  display: none !important;
}

.collapse {  
  display: none !important;
}